import { Category, Post, Work } from 'generated-graphql';
import Arrow from '/public/svgs/up-right-arrow.svg';
import {
  Categories,
  Excerpt,
  FourthParagraph,
  SixthTitle,
  Truncated,
} from '@/componentsWP/StyledComponentsLib/StyledComponentsLib';
import styled from 'styled-components';
import Link from 'next/link';
import dayjs from 'dayjs';
import LazyLoad from 'react-lazy-load';
import clip from 'text-clipper';
import get from 'lodash/get';
import getResourceItemUrl from '@/helpers/resourceItemUrl';
import Image from 'next/image';
// import clip from 'text-clipper';

const ResourceItemWrapper = styled.div`
  margin: 24px 0;
  h3,
  h5 {
    margin: 10px 0;
  }

  h6 {
    display: contents;
  }
  p {
    margin-bottom: 10px;
  }
  img {
    border-radius: 16px;
  }
`;

export const Content = styled.div`
  padding-top: 20px;
`;

const ImgDiv = styled.div`
  img {
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
    max-width: 100%;
  }
`;

interface ResourceItemProps {
  item: Post | Work;
  hideExcerpt?: boolean;
  isFeatured?: boolean;
}

const ResourceItem: React.FC<ResourceItemProps> = ({ item, hideExcerpt, isFeatured }) => {
  const { url, contentType, target } = getResourceItemUrl(item);

  const date = item.date ? dayjs(item.date).format('DD MMMM YYYY') : '';
  const image = get(
    item,
    'featuredImage.node.mediaDetails.sizes.0.sourceUrl',
    get(item, 'featuredImage.node.sourceUrl'),
  );

  const Item = (
    <ResourceItemWrapper>
      {image && (
        <ImgDiv>
          <Image src={image} alt={item.title || ''} width={896} height={480} className='img-fluid' quality={100} />
        </ImgDiv>
      )}
      <Content>

        {item.__typename === "Work" && <FourthParagraph TypeWight="SemiBold" className="mt-0">
          {item.works?.client}
        </FourthParagraph>}
        <FourthParagraph TypeWight="SemiBold" className="mt-0">
          {/* {`${item.publishers?.nodes[0] ? item.publishers?.nodes[0].name + " • " : ""}  ${date}`} */}
          {contentType && contentType?.name}{' '}
          {`${item.__typename === 'Post' && item?.publishers?.nodes[0]
            ? ` • ${item?.publishers?.nodes[0].name}`
            : ''
            }`}
        </FourthParagraph>
        <div className="d-flex justify-content-between aligin-items-top">
          <SixthTitle>
            <Truncated minHeight="64px" lines={2}>
              {item.title}
            </Truncated>
          </SixthTitle>
          <div className="mt-1 ps-3">
            <Arrow />
          </div>
        </div>
        {(!hideExcerpt && item.__typename === "Post" && (item.excerpt || item.customExcerpt)) && (
          <Excerpt style={{ color: "#667085", fontSize: "16px", lineHeight: "24px" }} dangerouslySetInnerHTML={{ __html: item.excerpt || item.customExcerpt || "" }} />
        )}
        {item.categories?.nodes.length ? (
          <div className={!hideExcerpt && item.__typename === "Post" ? "mt-4" : ""}>
            {item.categories.nodes.map(
              (c: Category) => {
                const showFeatured = isFeatured && c.name === "Featured";
                return (showFeatured || c.name !== "Featured") &&
                  (c.parent?.node || showFeatured) &&
                  c?.color?.backgroundColor && (
                    <Categories
                      color={c?.color?.color || ''}
                      bgColor={c?.color?.backgroundColor || ''}
                      key={c.id}
                    >
                      {c.name}
                    </Categories>
                  );
              }

            )}
          </div>
        ) : null}
      </Content>
    </ResourceItemWrapper>
  )
  return (
    <a style={{ color: '#000', textDecoration: "none" }} href={url} target={target}>
      {Item}
    </a>
  );
};

export default ResourceItem;
